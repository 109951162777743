@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafbfe;
  transition: all 0.5s linear;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  cursor: pointer;
}

button {
  cursor: pointer;
}

main {
  position: relative;
  transition: margin-left 0.5s;
  padding: 16px;
}

.collapsed {
  margin-left: 75px;
}

.full {
  margin-left: 260px;
}

root {
  /** sunny side **/
  --blue-background: #c2e9f6;
  --blue-border: #72cce3;
  --blue-color: #96dcee;
  --yellow-background: #fffaa8;
  --yellow-border: #f5eb71;
  /** dark side **/
  --indigo-background: #808fc7;
  --indigo-border: #5d6baa;
  --indigo-color: #6b7abb;
  --gray-border: #e8e8ea;
  --gray-dots: #e8e8ea;
  /** general **/
  --white: #fff;
}

.toggler {
  position: fixed;
  top: 27px;
  left: 30%;
  z-index: 10000;
  justify-self: center;
}

.toggle--checkbox {
  display: none;
}

.toggle--checkbox:checked ~ .background {
  background: var(--indigo-background);
}
.toggle--checkbox:checked + .toggle--label {
  background: var(--indigo-color);
  border-color: var(--indigo-border);
  /** Change the cloud to stars **/
  /** Change the sun into the moon **/
  /** Show the dimples on the moon **/
}
.toggle--checkbox:checked + .toggle--label .toggle--label-background {
  left: 60px;
  width: 5px;
}
.toggle--checkbox:checked + .toggle--label .toggle--label-background:before {
  width: 5px;
  height: 5px;
  top: -25px;
}
.toggle--checkbox:checked + .toggle--label .toggle--label-background:after {
  width: 5px;
  height: 5px;
  left: -30px;
  top: 20px;
}
.toggle--checkbox:checked + .toggle--label:before {
  background: var(--white);
  border-color: var(--gray-border);
  animation-name: switch;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
}
.toggle--checkbox:checked + .toggle--label:after {
  transition-delay: 350ms;
  opacity: 1;
}
.toggle--label {
  /** Placeholder element, starting at blue **/
  width: 60px;
  height: 30px;
  background: var(--blue-color);
  border-radius: 50px;
  border: 5px solid var(--blue-border);
  display: flex;
  position: relative;
  transition: all 350ms ease-in;
  /** The sun cloud and moon stars **/
  /** Sun/Moon element **/
  /** Gray dots on the moon **/
}
.toggle--label-background {
  width: 10px;
  height: 5px;
  border-radius: 5px;
  position: relative;
  background: var(--white);
  left: 25px;
  top: 10px;
  transition: all 150ms ease-in;
  display: none;
}
.toggle--label-background:before {
  content: "";
  position: absolute;
  top: -5px;
  width: 20px;
  height: 5px;
  border-radius: 5px;
  background: var(--white);
  left: -20px;
  transition: all 150ms ease-in;
}
.toggle--label-background:after {
  content: "";
  position: absolute;
  top: 5px;
  width: 15px;
  height: 5px;
  border-radius: 5px;
  background: var(--white);
  left: -10px;
  transition: all 150ms ease-in;
}
.toggle--label:before {
  animation-name: reverse;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  transition: all 350ms ease-in;
  content: "";
  width: 10px;
  height: 10px;
  border: 5px solid var(--yellow-border);
  top: 0;
  left: 2px;
  position: absolute;
  border-radius: 15px;
  background: var(--yellow-background);
}
.toggle--label:after {
  transition-delay: 0ms;
  transition: all 250ms ease-in;
  position: absolute;
  content: "";
  box-shadow: var(--gray-dots) -13px 0 0 2px, var(--gray-dots) -24px 14px 0 -2px;
  left: 30px;
  top: 2px;
  width: 7px;
  height: 7px;
  background: transparent;
  border-radius: 50%;
  opacity: 0;
}

@keyframes switch {
  0% {
    left: 4px;
  }
  60% {
    left: 4px;
    width: 15px;
  }
  100% {
    left: 20px;
    width: 15px;
  }
}
@keyframes reverse {
  0% {
    left: 50px;
    width: 15px;
  }
  60% {
    left: 25px;
    width: 15px;
  }
  100% {
    left: 4px;
  }
}
