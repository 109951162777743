#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#table-wrapper {
  background: #fafafb;
  margin-top: 1em;
  padding-bottom: 2em;
  position: relative;
}

#data {
  text-align: left;
  border-collapse: collapse;
  border: none;
  width: 100%;
}

#data td,
#data th {
  border: none;
  padding: 15px;
}

#data tr {
  border-bottom: solid 1px #c1c0ff;
}

#data tr:hover {
  background-color: #ddd;
}

#data th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #dbcee9;
  color: #19073b;
  border: none;
  font-size: 14px;
  text-transform: capitalize;
}

/* Customize the label (the container) */
.customContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  border-radius: 10%;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.customContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.customCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.customContainer:hover input ~ .customCheckmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.customContainer input:checked ~ .customCheckmark {
  /* background-color: #eee; */
  background-color: #7211d4;
}

/* Create the customCheckmark/indicator (hidden when not checked) */
.customCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the customCheckmark when checked */
.customContainer input:checked ~ .customCheckmark:after {
  display: block;
}

/* Style the customCheckmark/indicator */
.customContainer .customCheckmark:after {
  left: 9.5px;
  top: 6px;
  width: 4px;
  height: 8px;
  border: solid white;
  // color: white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.overlay {
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.join {
  position: absolute;
  top: 50%;
  left: 30%;
  width: 500px;
  background: #ffffff;
  box-shadow: 8px 10px 15px rgba(114, 17, 212, 0.2);
  border-radius: 20px;
  padding: 1em;

  p {
    background: #dbcee9;
    color: #19073b;
    font-size: 18px;
    padding: 0.5em 2em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: bold;
  }

  .action {
    display: flex;
    padding: 3em;
    background: #fafafb;
    justify-content: space-between;

    button {
      width: 130px;
      height: 45px;
      background: #dbcee9;
      border-radius: 10px;
      font-size: 18px;
      border: none;
      text-transform: uppercase;
      cursor: pointer;
    }

    .no {
      background: transparent;
      border: 1px solid #dbcee9;
    }
  }
}

select {
  outline: none;
}
