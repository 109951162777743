.filterer {
  background: #fff;
  position: relative;
  padding: 1em 0;
}
.customDates {
  display: flex;
  flex-direction: row;
  .dateInput {
    margin: 0px 10px;
    width: 85px;
  }
}

.col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #fff;
  padding: 1em;
  gap: 2em;
  z-index: 1000;

  .box {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .check {
      background: #fefdff;
      box-shadow: 0px 0px 4px rgba(84, 90, 109, 0.61);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      padding: 0.5em 0;

      div {
        padding: 0.5em 0.5em;
        display: flex;
        align-items: center;
        gap: 2em;
      }

      button {
        background: transparent;
        border: none;
        padding: 1em;
        font-weight: bold;
      }
    }
  }
}

// .widerCol {
//   grid-template-columns: repeat(7, 1fr);

// }

.selectBox,
.inputBox {
  height: 45px;
  background: #fefdff;
  border: 1px solid rgba(153, 153, 153, 0.7);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
  font-size: smaller;
}

.subInputBox {
  height: 45px;
  width: 100%;
  background: #fefdff;
  border: 1px solid rgba(153, 153, 153, 0.7);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
}

.selectBox {
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}
.btns {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
}
.cancel,
.apply {
  height: 45px;
  border: none;
  width: 200px;
  border-radius: 8px;
  font-weight: bold;
}

.apply {
  background: #2ea44e;
  color: #fff;
  cursor: pointer;
}

.cancel {
  background: transparent;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radiobutt {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .radiobutt {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .radiobutt {
  background-color: #7211d4;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radiobutt:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .radiobutt:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .radiobutt:after {
  top: 6px;
  left: 6px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: white;
}
