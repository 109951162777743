ul.breadcrumb li {
  display: inline;
  font-size: 16px;
}
ul.breadcrumb li + li:before {
  padding: 8px;
  content: "/\00a0";
}
ul.breadcrumb li a {
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  text-decoration: underline;
}

ul.breadcrumb .fade a {
  color: rgba(33, 62, 113, 0.6);
}
